import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import css from "./assets/css/main.css";

Vue.config.productionTip = false;

window.history.pushState(null, null, null);
window.addEventListener("popstate", function () {
  window.history.pushState(null, null, null);
  return;
});

new Vue({
  router,
  store,
  css,
  render: (h) => h(App),
}).$mount("#app");
