<template>
  <div class="container">
    <div class="main">
      <div v-if="isFinished">
        <div v-show="imageLoaded">
          <div class="top_image">
            <img
              :src="require(`@/assets/img/${result.image}`)"
              @load="onImageLoaded"
            />
          </div>
          <div class="statement">
            <p v-if="scoreText">{{ scoreText }}</p>
            <p>{{ result.statement }}</p>
          </div>
          <div v-for="(choice, index) in result.choices" :key="index">
            <AnswerButton
              :choice="choice"
              @choice-click="selected($event)"
              @hide-image="hideImage($event)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div v-show="imageLoaded">
          <div class="top_image">
            <img
              :src="
                require(`@/assets/img/${quizzes.questions[currentQuiz].image}`)
              "
              @load="onImageLoaded"
            />
          </div>
          <div class="statement">
            <p>{{ currentQuizText }}</p>
          </div>
          <div
            v-for="(choice, index) in quizzes.questions[currentQuiz].choices"
            :key="index"
          >
            <AnswerButton
              :choice="choice"
              @choice-click="selected($event)"
              @hide-image="hideImage($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quizzes from "@/assets/json/quizzes.json";
import AnswerButton from "@/components/AnswerButton";

const GTAG_EVENT = {
  RESULT_PAGE_VIEWED: "result_page_viewed",
  ALL_CORRECT_PAGE_VIEWED: "all_correct_page_viewed",
  SHARE_BUTTON_CLICKED: "share_button_clicked",
  FOLLOW_BUTTON_CLICKED: "follow_button_clicked",
  RETRY_BUTTON_CLICKED: "retry_button_clicked",
  URL_BUTTON_CLICKED: "url_button_clicked",
};
const GTAG_CONFIG = {
  PAGE_VIEW_EVENT: {
    event_category: "engagement",
    event_label: "page_view",
  },
  SELECTED_EVENT: {
    event_category: "engagement",
    event_label: "selected",
  },
};

export default {
  name: "Home",
  components: { AnswerButton },
  data() {
    return {
      quizzes,
      currentQuiz: 0,
      currentQuizText: null,
      correctNum: 0,
      selectedChoice: undefined,
      result: undefined,
      isFinished: false,
      scoreText: null,
      imageLoaded: false,
    };
  },
  methods: {
    hideImage() {
      this.imageLoaded = false;
    },
    /* 選択肢が選ばれたら発火 */
    selected(event) {
      /* 選ばれた回答 */
      this.selectedChoice = event;

      /* Google Analytics イベント名をセット */
      this.setSelectedGtagEvent(this.selectedChoice.meta);

      /* retryが選択されたら */
      if (this.selectedChoice.meta === "retry") {
        this.reset();
        /* 再トライするたびにクイズはランダムオーダーで表示 */
        if (process.env.VUE_APP_IS_RANDOM === "true") {
          this.makeQuestionsRandom();
        }
        this.currentQuizText = this.getQuizText(
          this.quizzes.questions[this.currentQuiz].statement
        );
        return;
      }

      this.currentQuiz++;

      if (this.isFinished) return;

      /* 答えが正解かどうか */
      if (this.selectedChoice.meta === "correct") {
        this.correctNum++;
      } else if (process.env.VUE_APP_END_ON_INCORRECT === "true") {
        /* 連続正解モードで間違えたら終了 */
        this.isFinished = true;
        this.result = this.getResult("incorrect");
        this.setPageViewGtagEvent(GTAG_EVENT.RESULT_PAGE_VIEWED);
        this.setUrl();
        return;
      }

      /* 最後の問題かどうか */
      this.isFinished = this.currentQuiz === this.quizzes.questions.length;

      if (this.isFinished) {
        /* 全問正解！ */
        if (this.correctNum === this.quizzes.questions.length) {
          this.result = this.getResult("all_correct");
          this.setPageViewGtagEvent(GTAG_EVENT.ALL_CORRECT_PAGE_VIEWED);
        } else {
          /* 正解数を表示するパターン */
          const scoreText = this.getShareText(this.quizzes.config.score_text);
          this.scoreText = scoreText;
          this.result = this.getResult(`result_${this.correctNum}`);
        }
        this.setPageViewGtagEvent(GTAG_EVENT.RESULT_PAGE_VIEWED);
        this.setUrl();
      } else {
        this.currentQuizText = this.getQuizText(
          this.quizzes.questions[this.currentQuiz].statement
        );
      }
    },
    setPageViewGtagEvent(eventName) {
      // eslint-disable-next-line no-undef
      gtag("event", eventName, GTAG_CONFIG.PAGE_VIEW_EVENT);
    },
    setSelectedGtagEvent(selected) {
      let eventName;
      if (selected === "share") {
        eventName = GTAG_EVENT.SHARE_BUTTON_CLICKED;
      } else if (selected === "follow") {
        eventName = GTAG_EVENT.FOLLOW_BUTTON_CLICKED;
      } else if (selected === "retry") {
        eventName = GTAG_EVENT.RETRY_BUTTON_CLICKED;
      } else if (selected && selected.match(/((https?|http)?:\/\/[^\s]*)/g)) {
        eventName = GTAG_EVENT.URL_BUTTON_CLICKED;
      } else {
        return;
      }
      // eslint-disable-next-line no-undef
      gtag("event", eventName, GTAG_CONFIG.SELECTED_EVENT);
    },
    setUrl() {
      let shareText;
      if (["all_correct", "incorrect"].includes(this.result.meta)) {
        shareText = this.getShareText(
          this.quizzes.config.twitter_share_text[this.result.meta]
        );
      } else {
        shareText = this.getShareText(
          this.quizzes.config.twitter_share_text.score
        );
      }

      this.result.choices.map((choice) => {
        if (choice.meta === "share") {
          choice.url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            shareText
          )}`;
        } else if (choice.meta === "follow") {
          choice.url = `https://twitter.com/intent/follow?screen_name=${this.quizzes.config.twitter_screen_name}`;
        } else if (choice.meta.match(/((https?|http)?:\/\/[^\s]*)/g)) {
          /* 任意のURLに誘導する場合は、metaにURLを指定する */
          choice.url = choice.meta;
        }
      });
    },
    getShareText(text) {
      return text
        .replace("${URL}", process.env.VUE_APP_URL)
        .replace("${SCORE}", this.correctNum);
    },
    getQuizText(text) {
      return text.replace("${CURRENT}", this.currentQuiz + 1);
    },
    getResult(resultType) {
      return this.quizzes.results.find((ele) => ele.meta === resultType);
    },
    makeQuestionsRandom() {
      this.quizzes.questions = this.quizzes.questions.sort(
        () => Math.random() - 0.5
      );
    },
    reset() {
      this.currentQuiz = 0;
      this.correctNum = 0;
      this.selectedChoice = undefined;
      this.scoreText = undefined;
      this.result = undefined;
      this.isFinished = false;
    },
    onImageLoaded() {
      this.imageLoaded = true;
    },
  },
  mounted() {
    /* 問題の出題順をランダムに変更 */
    if (process.env.VUE_APP_IS_RANDOM === "true") {
      this.makeQuestionsRandom();
    }
    this.currentQuizText = this.getQuizText(
      this.quizzes.questions[this.currentQuiz].statement
    );
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 420px;
  margin: 0 auto;
  background-color: white;
  font-family: Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  .main {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 25px;
    color: #222;
    line-height: 1.4;
    p {
      white-space: pre-wrap;
    }
    .top_image {
      img {
        max-width: 100%;
        border-radius: 14px;
      }
    }
    .statement {
      margin-block-start: 1.2em;
      margin-block-end: 1.2em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      p {
        font-size: 1em;
        display: block;
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  /* ダークモードの時 */
  .container {
    background-color: #222;
    .main {
      color: white;
    }
  }
}
</style>
