<template>
  <div class="button_area">
    <button @click="send">{{ choice.statement }}</button>
  </div>
</template>

<script>
export default {
  props: {
    choice: {
      type: Object,
      require: true,
    },
  },
  methods: {
    send() {
      if (this.choice.url) {
        window.open(this.choice.url, "_blank");
      } else {
        this.$emit("choice-click", this.choice);
        this.$emit("hide-image", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button_area {
  button {
    display: block;
    border-radius: 18px;
    width: 90%;
    margin: 0 auto 10px auto;
    padding: 0.2em 0.5em;
    border: solid 1px #1da1f2;
    background-color: white;
    color: #1da1f2;
    text-align: center;
    font-weight: bold;
    transition-duration: 0.2s;
    transition-property: background-color;
    font-size: 1em;
    user-select: none;
    a {
      text-decoration-color: #1da1f2;
      text-decoration: none;
    }
  }
}

@media (prefers-color-scheme: dark) {
  /* ダークモードの時 */
  .button_area {
    button {
      background-color: #222;
    }
  }
}
</style>
